<template>
  <div>
    <v-container>
      <v-toolbar
        dense
        flat
        color="transparent"
      >
        <v-btn
          icon
          to="MyPlayer"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-toolbar-title>{{ $t('wanjia_shuju') }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <div>
        <v-card
          flat
          class="rounded-xl mt-3 px-4"
        >
          <v-chip-group
            v-model="selectday"
            color="orange"
            class="pt-3"
          >
            <v-chip
              value="day30"
              small
              @click="changeDate(29)"
            >
              {{ $t('dates_30') }}
            </v-chip>
            <v-chip
              value="day7"
              small
              @click="changeDate(6)"
            >
              {{ $t('dates_7') }}
            </v-chip>
            <v-chip
              value="today"
              small
              @click="changeDate(0)"
            >
              {{ $t('dates_today') }}
            </v-chip>

            <v-spacer />

            <v-chip
              small
              @click="Filter_date = !Filter_date"
            >
              <v-icon x-small>
                mdi-filter
              </v-icon>
              {{ $t('dates_shaixuan') }}
            </v-chip>
          </v-chip-group>
          <div class="text-right text-caption px-4 opacity-5">
            {{ $t('dates') }}: {{ dateRangeText }}
          </div>

          <v-list>
            <v-divider class="opacity-3" />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t('wanjia_xinzeng') }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-subtitle>
                  {{ total_players }}
                  <span class="opacity-5">/{{ $t('ren') }}</span>
                </v-list-item-subtitle>
              </v-list-item-action>
            </v-list-item>
            <v-divider class="opacity-3" />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t('zhuanru') }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-subtitle>
                  {{ total_player_cashout }}
                </v-list-item-subtitle>
              </v-list-item-action>
            </v-list-item>
            <v-divider class="opacity-3" />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t('zhuanchu') }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-subtitle>
                  {{ total_player_topup }}
                </v-list-item-subtitle>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>

        <v-card
          flat
          rounded="xl"
          class="mt-3 py-3"
        >
          <v-list>
            <v-list-item>
              <v-list-item-avatar width="100px">
                <v-list-item-action-text>
                  {{ $t('dates') }}
                </v-list-item-action-text>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-action-text>{{ $t('wanjia_xinzeng') }}</v-list-item-action-text>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>{{ $t('zhuanchu_jing') }}</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>

            <v-list-item
              v-for="(item, index) in list"
              :key="'r'+index"
              @click="showdetail(item)"
            >
              <v-list-item-avatar width="100px">
                <v-list-item-action-text>
                  {{ item.day }}
                </v-list-item-action-text>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>{{ item.newplayers }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-subtitle>{{ item.profit }}</v-list-item-subtitle>
              </v-list-item-action>
              <v-list-item-icon>
                <v-icon>
                  mdi-chevron-down
                </v-icon>
              </v-list-item-icon>
            </v-list-item>

            <div
              v-if="(page-1)<pagecount"
              class="text-center my-3"
              @click="playerdata"
            >
              <small>{{ $t('jzgd') }}</small>
            </div>
          </v-list>
        </v-card>
      </div>
    </v-container>

    <v-bottom-sheet
      v-model="Filter_date"
      inset
      max-width="600px"
    >
      <v-sheet
        class="rounded-t-xl pb-10"
      >
        <v-container>
          <v-row
            dense
            align="center"
          >
            <v-col cols="4">
              <v-btn
                icon
                @click="Filter_date = false"
              >
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="4"
              class="text-center"
            >
              {{ $t('dates_shaixuan') }}
            </v-col>
          </v-row>
        </v-container>
        <v-divider class="opacity-3" />

        <div class="pa-3 text-center text-body-2 primary--text">
          {{ dateRangeText }}
        </div>

        <v-divider class="opacity-3" />

        <v-date-picker
          v-model="picker"
          range
          full-width
          flat
          color="btn_bg"
          header-color="btn_bg"
          no-title
          @change="selectdaterange"
        />
      </v-sheet>
    </v-bottom-sheet>
    <v-bottom-sheet
      v-model="detail_daily"
      inset
      max-width="600px"
    >
      <v-sheet
        class="rounded-t-xl pb-10"
      >
        <v-container>
          <v-row
            dense
            align="center"
          >
            <v-col cols="4">
              <v-btn
                icon
                @click="detail_daily = false"
              >
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="4"
              class="text-center"
            >
              {{ $t('xiangqing') }}
            </v-col>
          </v-row>
        </v-container>

        <v-list
          class="py-0 px-3"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('dates') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                {{ detail.day }}
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('wanjia_xinzeng') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                {{ detail.newplayers }}
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('zhuanru') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                {{ detail.player_cashout }}
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('zhuanchu') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                {{ detail.player_topup }}
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('zhuanchu_jing') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <small class="mx-1 opacity-3">
                  ({{ $t('zhuanru') }} - {{ $t('zhuanchu') }})
                </small>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                {{ detail.profit }}
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import dayjs from 'dayjs'
  export default {
    data: () => ({
      selectday: 'day7',
      picker: [],
      Filter_date: false,
      detail_daily: false,
      total_player_cashout: 0,
      total_player_topup: 0,
      total_players: 0,
      page:1,
      pagecount:1,
      list:[],
      detail: {},
      empty: false
    }),

    computed: {
      dateRangeText:{
        get: function () {
          return dayjs(this.picker[0]).format('DD/MM/YYYY')+' ~ '+dayjs(this.picker[1]).format('DD/MM/YYYY')
        },
        set: function (newVal) {
        }
      },
    },
    watch: {
      dateRangeText: {
        handler(newVal, oldVal) {
          this.picker.sort()
        }
      },
    },
    created() {
      if(this.selectday == 'day30'){
        this.picker = [dayjs().subtract(29, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
      }
      if(this.selectday == 'day7'){
        this.picker = [dayjs().subtract(6, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
      }
      if(this.selectday == 'today'){
        this.picker = [dayjs().subtract(0, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
      }
    },
    mounted() {
      this.myplayers_dashboard()
      this.playerdata()
    },
    methods: {
      changeDate(d){
        this.picker = [dayjs().subtract(d, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
        this.selectdaterange()
      },
      selectdaterange(){
        this.page = 1
        this.pagecount = 1
        this.list = []
        this.Filter_date = false
        this.myplayers_dashboard()
        this.playerdata()
      },
      myplayers_dashboard() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          startdate:this.picker[0],
          enddate:this.picker[1],
        }
        this.$server.myplayers_dashboard(paramObj).then((response) => {
          if(response.code==200){
            this.total_player_cashout = response.data.total_player_cashout
            this.total_player_topup = response.data.total_player_topup
            this.total_players = response.data.total_players
          }
        })
      },
      playerdata() {
        if(this.page>this.pagecount){
          return false
        }
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          page:this.page,
          startdate:this.picker[0],
          enddate:this.picker[1],
        }
        this.$server.playerdata(paramObj).then((response) => {
          if(response.code==200){
            if(response.data.total==0){
              this.empty = true
            }
            this.pagecount = response.data.last_page
            if(response.data.data.length>0){
              this.empty = false
              this.list.push(...response.data.data)
            }
            ++this.page
          }else{
            this.$snackbar.error(response.msg)
          }
        })
      },
      showdetail(item) {
        this.detail = item
        this.detail_daily=true
      }
    },
  }
</script>